body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: darkgray lightgray; /* scroll thumb and track */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fullPage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: lightgray; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: darkgray; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid darkgray; /* creates padding around scroll thumb */
}

.fullPage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
